import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Config from '../../config/index';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Loader from '../../elements/loader';
import {Link} from "react-router-dom";
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import  {InputBase, Drawer, Badge, Chip, Checkbox, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');
const priceType = {"old":"Old Pricing", 
    "price_update_request":"Pricing Update Requested",
    "new_price_existing":"New Pricing for Existing",
    "new_price_new_cust":"New Pricing for New Customers"}
const subStatus = {"active":"Active","cancel_at_end":"Cancel At End"}
const subInterval = {"month":"Monthly","year":"Yearly"}

class Subscriptions extends Component {
    constructor(props) {
        super(props); 
        this.state={
            subscriptions:[],
            isLoading:true,
            params: {  
                sort: {"end_date":"asc"},
                filter: {},
                page: 0
            }, 
            open:false,
            more_filters_open: false,
            total_subscriptions: 0,
            records_per_page:20,
            updateSubscription:[],
            selectedSubStatus:"",
            selectedPriceType:"",
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.loadSubscriptions = this.loadSubscriptions.bind(this);
        this.searchtitleInputRef = React.createRef();
        this.subIdInputRef = React.createRef();
        this.instituteNameInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.startDateFromInputRef = React.createRef();
        this.startDateToInputRef = React.createRef();
        this.endDateFromInputRef = React.createRef();
        this.endDateToInputRef = React.createRef();        
        this.planNameInputRef = React.createRef();
        this.priceIdInputRef = React.createRef();       
    }
    componentDidMount(){
        this.loadSubscriptions(this.state.params);
    }
    loadSubscriptions= (params, preReset = false) => {
        if ( preReset === true ) {  
            params.page = 0;
            this.setState({
                isLoading: true,
                subscriptions:[],
                more_filters_open: false
            });            
        }
        let self=this;
        axios.get( Config.apiBaseURL + '/module_subscriptions',{params:params})
        .then( response => {
            this.setState({
                subscriptions:response.data.module_subscriptions, 
                records_per_page: response.data.records_per_page,
                total_subscriptions: response.data.total_module_subscriptions,
                params: params,
            });
            const elements = document.querySelectorAll(".subCheckbox input[type='checkbox']");
                elements.forEach(element => {
                if(element.checked){
                   element.click();
                } // Programmatically trigger a click event
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading subscriptions data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false
            });
        });
    }
    domainUrlClick(url){
        window.open(url);
    }
    handleChangePage = (event, newPage) => {
        let params = this.state.params;
        params.page = newPage;
        this.loadSubscriptions(params);
    };
    handleSortChange = (sortAttr, sortDir) => event => {
        let params = this.state.params;
        params.sort = {}; 
        params.sort[sortAttr] = sortDir;
        this.loadSubscriptions(params);
    };
    handleCheckboxChange = (e,subscription)=>{
        if(e.target.checked){
            let subData = this.state.updateSubscription
            subData.push(subscription)
            this.setState({
                updateSubscription:subData
            })            
        }
    } 
    detectEnter = (e) => {
        if(e.keyCode == 13){
            this.triggerBasicFilters();
        }
    }
    triggerBasicFilters = (e) => {
        const subId = this.searchtitleInputRef.current.value;
        let params = this.state.params;
        params.filter['sub_id'] = ( subId !== '' ) ? subId : undefined;
        this.loadSubscriptions(params, true);
    }
    handleFiltersToggle = () => this.setState({more_filters_open: !this.state.more_filters_open});
    triggerMoreFilters = (e) => {
        e.preventDefault();
        let params = this.state.params;
        const cust_name = this.instituteNameInputRef.current.value;
        const email = this.emailInputRef.current.value;
        const startForm = this.startDateFromInputRef.current.value;
        const startTo = this.startDateToInputRef.current.value;
        const endForm = this.endDateFromInputRef.current.value;
        const endTo = this.endDateToInputRef.current.value;
        const plan_name =this.planNameInputRef.current.value;
        const price_id =this.priceIdInputRef.current.value;
        const sub_id = this.subIdInputRef.current.value;
        const status = this.state.selectedSubStatus;
        const price_type = this.state.selectedPriceType;
        params.filter['sub_id'] = ( sub_id !== '' ) ? sub_id : undefined;
        params.filter['cust_name'] = ( cust_name !== '' ) ? cust_name : undefined;
        params.filter['plan_name'] = ( plan_name !== '' ) ? plan_name : undefined;        
        params.filter['price_id'] = ( price_id !== '' ) ? price_id : undefined; 
        params.filter['email'] = ( email !== '' ) ? email : undefined;  
        params.filter['start_date_from'] = ( startForm !== '' ) ? startForm : undefined;
        params.filter['start_date_to'] = ( startTo !== '' ) ? startTo : undefined; 
        params.filter['end_date_from'] = ( endForm !== '' ) ? endForm : undefined;
        params.filter['end_date_to'] = ( endTo !== '' ) ? endTo : undefined;
        params.filter['status'] = ( status !== '' ) ? status : undefined; 
        params.filter['price_type'] = ( price_type !== '' ) ? price_type : undefined;
        this.loadSubscriptions(params, true);
    }
    resetFilters = () => {
        let params = this.state.params;
        params.filter = {};
        this.searchtitleInputRef.current.value=null;
        this.subIdInputRef.current.value=null;
        this.instituteNameInputRef.current.value=null;
        this.emailInputRef.current.value=null;
        this.planNameInputRef.current.value=null;        
        this.priceIdInputRef.current.value=null;
        this.startDateFromInputRef.current.value=null; 
        this.startDateToInputRef.current.value=null;
        this.endDateFromInputRef.current.value=null; 
        this.endDateToInputRef.current.value=null;
        this.setState({
            selectedSubStatus:"",
            selectedPriceType:""
        })
        this.loadSubscriptions(params, true);
    }
    subStatusHandle = (event) => {
        this.setState({
            selectedSubStatus:event.target.value
        })
    }
    subPriceTypeHandle = (event) => {
        this.setState({
            selectedPriceType:event.target.value
        })
    }
    openUpgradeModal = ()=>{
        if(this.state.updateSubscription.length > 0){           
            this.setState({open:true});
            setTimeout(()=>{this.upgradeSubscription(this.state.updateSubscription[0].id)},2000);
        }else{
            this.props.enqueueSnackbar('Select at least one subscription to upgrade.', {
                variant: 'error',
            });
        }
    }
    ModelhandleClose = () => {
        this.setState({
            open:false,
            updateSubscription:[]
        })
        this.loadSubscriptions(this.state.params);             
    };
    upgradeSubscription = (id)=>{
        var currentIndex = this.state.updateSubscription.findIndex(obj=>obj.id == id)
        var elm = document.getElementById('upgradeStatus_'+id);
        if(elm){
            elm.innerHTML = '<span class="MuiChip-label">Upgrading</span>'
            elm.style.backgroundColor = "#3f51b5";

            let self=this;
            axios.post( Config.apiBaseURL + '/subscriptions_upgrade/'+id)
            .then( response => {
                if(response.data.success == 1){
                    elm.innerHTML = '<span class="MuiChip-label">Success</span>'
                    elm.style.backgroundColor = "#A5FDCA";
                }else{
                    elm.innerHTML = '<span class="MuiChip-label">Error</span>'
                    elm.style.backgroundColor = "#F25757 ";
                }
            })
            .catch(function (error) {
                if(error.response && error.response.status === 500) {                   
                    self.props.enqueueSnackbar(error.response.data.errors.message, {
                        variant: 'error',
                    });
                }else if (error.response && error.response.status === 401){
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }
                else { // any other error 
                        self.props.enqueueSnackbar('Error! While upgrading subscription.', {
                        variant: 'error',
                    });
                }
            }).then(function(){
                var nextIndex = currentIndex + 1;
                if(nextIndex < self.state.updateSubscription.length){
                    self.upgradeSubscription(self.state.updateSubscription[nextIndex].id);
                }else{
                    var closeIcon = document.getElementById('subUpgradeClose');
                    if(closeIcon){
                        closeIcon.style.display = "block";
                    }
                    self.loadSubscriptions(self.state.params);
                }
            })
        }
    }
    render(){
        const { selectedSubStatus } = this.state;
        let basicFilter;
        let more_filters =  <Drawer
                                anchor="right"
                                open={this.state.more_filters_open}
                                width={500}>
                                <div>
                                    <div className="moreFilterTitle">Filters</div>
                                    <CancelIcon onClick={this.handleFiltersToggle} className="cancelIcon"/>
                                </div>
                                <div  style={{minWidth:400}}>
                                    <form method="POST" onSubmit={this.triggerMoreFilters} 
                                        style={{margin:30,marginTop:20,maxWidth:400}}>
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.instituteNameInputRef
                                                    }}
                                                    id="title"
                                                    label="Customer Name"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['cust_name']) ? this.state.params.filter['cust_name'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.subIdInputRef
                                                    }}
                                                    id="subDomain"
                                                    label="Subscription ID"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['sub_id']) ? this.state.params.filter['sub_id'] : null}
                                                />   
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.emailInputRef
                                                    }}
                                                    id="email"
                                                    label="Email"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['email']) ? this.state.params.filter['email'] : null}
                                                /> 
                                            </Grid>
                                            <Grid item xs={6}>                                                
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.planNameInputRef
                                                    }}
                                                    id="email"
                                                    label="Plane Name"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['plan_name']) ? this.state.params.filter['plan_name'] : null}
                                                /> 
                                            </Grid>
                                        </Grid> 
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.priceIdInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="Price ID"
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={(this.state.params.filter['price_id']) ? this.state.params.filter['price_id'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                               <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Status"
                                                        value={selectedSubStatus}
                                                        onChange={this.subStatusHandle}
                                                        >
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value="active">Active</MenuItem>
                                                        <MenuItem value="cancel_at_end">Cancel At End</MenuItem>                                                        
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={12}>
                                               <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="demo-simple-select-outlined-label">Price Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Price Type"
                                                        value={this.state.selectedPriceType}
                                                        onChange={this.subPriceTypeHandle}
                                                        >
                                                        <MenuItem value="">Select</MenuItem>
                                                        {Object.keys(priceType).map(key=>
                                                            <MenuItem value={key}>{priceType[key]}</MenuItem>
                                                        )}                                                        
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>                              
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.startDateFromInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="Start Date (From)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['start_date_form']) ? this.state.params.filter['start_date_from'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.startDateToInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="Start Date (To)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['start_date_to']) ? this.state.params.filter['start_date_to'] : null}
                                                />
                                            </Grid> 
                                        </Grid>
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.endDateFromInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="End Date (From)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['end_date_form']) ? this.state.params.filter['end_date_from'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.endDateToInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="End Date (To)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['end_date_to']) ? this.state.params.filter['end_date_to'] : null}
                                                />
                                            </Grid> 
                                        </Grid>
                                        <div style={{marginTop:20,marginBottom:20}} >
                                            <Button variant="contained" size="medium" color="primary" type="submit">
                                                Apply
                                            </Button>&nbsp;&nbsp;
                                            <Button variant="contained" size="medium" onClick={this.resetFilters} type="submit" >
                                                Reset
                                            </Button>
                                        </div> 
                                    </form>  
                                </div> 
                            </Drawer>;
        let data_table;
        if ( this.state.isLoading ) {
            data_table = <Loader type='block'/>;
        }else{
            let subscriptionData = [];
                for(const i in this.state.subscriptions){ 
                    var StartDate = "-";
                    var EndDate = "-"
                    if(this.state.subscriptions[i].start_date){
                        var start_date = this.state.subscriptions[i].start_date.split('T')
                        let sDate = start_date[0];
                        var StartDate= moment(sDate).format("DD MMM YYYY");
                    }
                    if(this.state.subscriptions[i].end_date){
                        var end_date = this.state.subscriptions[i].end_date.split('T')
                        let eDate = end_date[0];
                        var EndDate= moment(eDate).format("DD MMM YYYY");
                    }
                    var checkbox = <Checkbox disabled/>
                    if(this.state.subscriptions[i].allow_update && this.state.subscriptions[i].price_type == 'old'){
                        checkbox = <Checkbox name='subCheck' className='subCheckbox' onChange={(e)=>{this.handleCheckboxChange(e,this.state.subscriptions[i])}} color='primary'/>
                    }
                    subscriptionData.push(
                        <TableRow>
                            <TableCell>{checkbox}</TableCell>
                            <TableCell>
                                <div className='TableWordBreak'>Subscription ID: {this.state.subscriptions[i].sub_id}</div>
                                <div className='TableWordBreak'>Plan Name: {this.state.subscriptions[i].plan_name}</div>
                                <div className='TableWordBreak'>Price ID: {this.state.subscriptions[i].price_id}</div>
                                <div className='TableWordBreak'>Price: {this.state.subscriptions[i].price_amount}</div>
                            </TableCell>
                            <TableCell>{subInterval[this.state.subscriptions[i].sub_interval]}</TableCell>
                            <TableCell>{subStatus[this.state.subscriptions[i].status]}</TableCell>
                            
                            <TableCell>{priceType[this.state.subscriptions[i].price_type]}</TableCell>
                            <TableCell>
                                <div className='TableWordBreak'>{this.state.subscriptions[i].cust_name}</div>
                                <div className='TableWordBreak'><Link to="#" className="Link" onClick={this.domainUrlClick.bind(this,this.state.subscriptions[i].cust_description)}>{this.state.subscriptions[i].cust_description}</Link></div>
                                <div className='TableWordBreak'>{this.state.subscriptions[i].cust_email}</div>
                            </TableCell>
                            <TableCell>
                                <div className='TableWordBreak'>{StartDate}</div>
                                <div className='TableWordBreak' style={{textAlign:"center"}}>to</div>
                                <div className='TableWordBreak'>{EndDate}</div>
                            </TableCell>              
                        </TableRow>  
                    );
                }
            basicFilter= <div style={{marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                            <div className="adminSearchBox" >
                                <div>
                                    <SearchIcon className="searchIcon" onClick={this.triggerBasicFilters}/>
                                </div>   
                                <div>
                                    <InputBase
                                        style={{width: "calc(100% - 10px)"}}
                                        placeholder="Search Subscription"
                                        inputProps={{
                                            ref: this.searchtitleInputRef
                                        }}
                                        onKeyUp={this.detectEnter}
                                        defaultValue={(this.state.params.filter['sub_id']) ? this.state.params.filter['sub_id'] : null} 
                                    />
                                </div>
                                <div style={{width:100}}>
                                    <div onClick={this.handleFiltersToggle} className="moreFilterLabel" >More Filters</div>
                                </div>
                            </div>
                            <Button variant='contained' color='primary' onClick={this.openUpgradeModal}>Upgrade</Button>
                        </div>
            data_table = <div style={{paddingTop:10}}> 
                            <Paper>
                                <Table stickyHeader={false}>
                                    <TableHead className="root">
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell width="80px"></TableCell>
                                            <TableCell>Plan Details</TableCell>
                                            <TableCell width="110px">
                                                <TableSortLabel
                                                    active={ (this.state.params.sort.sub_interval) ? true : false} 
                                                    direction={ (this.state.params.sort.sub_interval) ? this.state.params.sort.sub_interval.toLowerCase() : "asc" } 
                                                    onClick={this.handleSortChange('sub_interval', (this.state.params.sort.sub_interval && this.state.params.sort.sub_interval.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                    Interval
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell width="110px">
                                                <TableSortLabel
                                                    active={ (this.state.params.sort.status) ? true : false} 
                                                    direction={ (this.state.params.sort.status) ? this.state.params.sort.status.toLowerCase() : "asc" } 
                                                    onClick={this.handleSortChange('status', (this.state.params.sort.status && this.state.params.sort.status.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                    Status
                                                </TableSortLabel>
                                            </TableCell>
                                            
                                            <TableCell width="150px">
                                                <TableSortLabel
                                                    active={ (this.state.params.sort.price_type) ? true : false} 
                                                    direction={ (this.state.params.sort.price_type) ? this.state.params.sort.price_type.toLowerCase() : "asc" } 
                                                    onClick={this.handleSortChange('price_type', (this.state.params.sort.price_type && this.state.params.sort.price_type.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                    Price Type
                                                </TableSortLabel>                                                
                                            </TableCell>
                                            <TableCell>Customer Details</TableCell>
                                            <TableCell width="120px">Period</TableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {subscriptionData}                              
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[]}
                                                count={this.state.total_subscriptions}
                                                rowsPerPage={this.state.records_per_page}
                                                page={this.state.params.page ? this.state.params.page : 0}
                                                onChangePage={this.handleChangePage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Paper>
                        </div>   
        }
        let upgradeSubscriptionData = [];
                for(const i in this.state.updateSubscription){ 
                    var StartDate = "-";
                    var EndDate = "-"
                    if(this.state.updateSubscription[i].start_date){
                        var start_date = this.state.updateSubscription[i].start_date.split('T')
                        let sDate = start_date[0];
                        var StartDate= moment(sDate).format("DD MMM YYYY");
                    }
                    if(this.state.updateSubscription[i].end_date){
                        var end_date = this.state.updateSubscription[i].end_date.split('T')
                        let eDate = end_date[0];
                        var EndDate= moment(eDate).format("DD MMM YYYY");
                    }
                    upgradeSubscriptionData.push(
                        <TableRow>
                            <TableCell>
                                <div className='TableWordBreak'>Subscription ID: {this.state.updateSubscription[i].sub_id}</div>
                                <div className='TableWordBreak'>Plan Name: {this.state.updateSubscription[i].plan_name}</div>
                                <div className='TableWordBreak'>Price ID: {this.state.updateSubscription[i].price_id}</div>
                                <div className='TableWordBreak'>Price: {this.state.updateSubscription[i].price_amount}</div>
                            </TableCell>
                            <TableCell>{subInterval[this.state.updateSubscription[i].sub_interval]}</TableCell>
                            <TableCell>{subStatus[this.state.updateSubscription[i].status]}</TableCell>
                            <TableCell>{priceType[this.state.updateSubscription[i].price_type]}</TableCell>
                            <TableCell>
                                <div className='TableWordBreak'>{this.state.updateSubscription[i].cust_name}</div>
                                <div className='TableWordBreak'><Link to="#" className="Link" onClick={this.domainUrlClick.bind(this,this.state.subscriptions[i].cust_description)}>{this.state.subscriptions[i].cust_description}</Link></div>
                                <div className='TableWordBreak'>{this.state.updateSubscription[i].cust_email}</div>
                            </TableCell>                            
                            <TableCell>
                                <div className='TableWordBreak'>{StartDate}</div>
                                <div className='TableWordBreak' style={{textAlign:"center"}}>to</div>
                                <div className='TableWordBreak'>{EndDate}</div>
                            </TableCell>
                            <TableCell><Chip label="Upgrade" id={"upgradeStatus_"+this.state.updateSubscription[i].id}/></TableCell>                           
                        </TableRow>  
                    );
                }
        let OpenDialog=<Dialog fullWidth maxWidth="xl" className="model" onClose={this.ModelhandleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                            <DialogTitle id="customized-dialog-title">
                                Upgrade Subscription      
                                <IconButton edge="start" color="inherit" className="dialogCloseIcon" id="subUpgradeClose" onClick={this.ModelhandleClose} aria-label="close" style={{display:"none"}}>
                                    <CloseIcon />
                                </IconButton> 
                            </DialogTitle>
                            <DialogContent dividers>
                            <Table stickyHeader={false}>
                                    <TableHead className="root">
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Plan Details</TableCell>
                                            <TableCell>Interval</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Price Type</TableCell>
                                            <TableCell>Customer Details</TableCell>
                                            <TableCell width="150px">Period</TableCell>
                                            <TableCell width="150px">Upgrade Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {upgradeSubscriptionData}
                                    </TableBody>                                    
                                </Table>
                            </DialogContent>
                        </Dialog>

        return (
            <div>
                <Grid container spacing={3} lg={12}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom >
                            Subscriptions
                        </Typography>       
                    </Grid>
                </Grid>
                {more_filters}
                {basicFilter}
                {data_table}
                {OpenDialog}                
            </div>
        );
    }
}

export default withSnackbar(Subscriptions)