import React from 'react';
import { makeStyles,createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Box } from '@material-ui/core'; 
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import { BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import { SnackbarProvider} from 'notistack';
import clsx from 'clsx';
import Dashboard from './project/dashboard/index';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CloseIcon from '@material-ui/icons/Close';
import Login from './login';
import NewUsers from './project/users';
import Customers from './project/customer/index';
import DeleteCustomer from './project/customer/delete_customer';
import Profile from './project/profile';
import SubscriptionDetails from './project/Subscription/index';
import SubscriptionAdd from './project/Subscription/add';
import SubscriptionEdit from './project/Subscription/edit';
import Partners from './project/parteners';
import InActive from './project/inactive_tenant';
import Renewals from './project/renewals';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeletedCustomerView from './project/customer/deleted_customer_view'
import InactiveSubscription from './project/customer/inactive_subscription';
import LockIcon from '@material-ui/icons/Lock';
import Subscriptions from './project/Subscriptions';

const axios = require('axios');
const useStyles = makeStyles((theme) =>
  	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			background:"#17b3a3",
		},
		toolbar: theme.mixins.toolbar,
    	menuButton: {
      		marginRight: theme.spacing(2),
		},
    	hide: {
      		display: 'none',
    	},
    	drawer: {
      		width: 240,
			flexShrink: 0,
		},
		drawerPaper: {
			  width: 240,
			  background:"#f3f5fd",
		},
    	drawerHeader: {
      		display: 'flex',
      		alignItems: 'center',
      		padding: theme.spacing(0, 1),
      		...theme.mixins.toolbar,
      		justifyContent: 'flex-end',
		},
    	content: {
      		flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -240,
		},
    	contentShift: {
      		transition: theme.transitions.create('margin', {
        		easing: theme.transitions.easing.easeOut,
        		duration: theme.transitions.duration.enteringScreen,
      		}),
      		marginLeft: 0,
    	},
  	}),
);
function logout(e) {
	e.preventDefault();
	
	axios.get('http://65.0.170.37:9002/logout')
	.then( response => {
	})
	.catch(function (error) {
	})
	.then(function () {
	});  

	localStorage.clear(); // remove all stored data 
	window.location = '/' ; // take the user to login screen
}
export default function App() {
	const classes = useStyles();
	let OpenSideBar = false;
	if(window.innerWidth >= 600){
		OpenSideBar = true;
	}

	const [open, setOpen] = React.useState(OpenSideBar);

	const resize = () => {
		let screenWidth = window.innerWidth;
		let OpenSideBar = false;
		if(screenWidth >= 600){
			OpenSideBar = true;
		}
		setOpen(OpenSideBar);
	};
	window.addEventListener("resize",resize);
	const handleDrawer = () => {
		setOpen(!open);
	};
	const [setSelectedIndex] = React.useState(1);

  
	const notistackRef = React.createRef();
    const onClickDismiss = key => () => { 
		notistackRef.current.closeSnackbar(key);
	}
	const menuId = 'primary-search-account-menu';
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [setMobileMoreAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};
	const renderMenu = (
    	<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}>
				<MenuItem onClick={logout}>Logout</MenuItem>
    	</Menu>
	);
	const user_menu = <div>
					<Avatar
						className="userImage"
						src={localStorage.getItem('user_image')}
						edge="end"
						aria-label="account of current user"
						aria-controls={menuId}
						aria-haspopup="true"
						onClick={handleProfileMenuOpen}
						color="inherit">
							<AccountCircle />
					</Avatar>
				  {renderMenu}
				</div>
	const appBar=	<AppBar 
						position="fixed"
						className={clsx(classes.appBar)}>
							<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawer}
								edge="start"
								className="sideBar"
								>
							<MenuIcon />
							</IconButton>
							<Box flexGrow={1}>
								<Typography variant="h6" noWrap>
									Master Admin
								</Typography>
							</Box>
							{user_menu}
						</Toolbar>
					</AppBar>
  	return (
		<Router>
			<Route exact path="/">
				<Login/>
			</Route>
    		<div className={classes.root}>
      			<CssBaseline />
				{appBar}
      			<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={open}
					classes={{
						paper: classes.drawerPaper,
					}}
					>
	    			<div className={classes.toolbar} />
					<List>
						<Link to="/admin/dashboard" className="Link" style={{color:'black'}}>
							<ListItem button key="dashboard_link" >
								<ListItemIcon><DashboardIcon /></ListItemIcon>
								<ListItemText primary="Dashboard" />
							</ListItem>
						</Link>
						<Link to="/admin/customers" className="Link" style={{color:'black'}}>
							<ListItem button key="Customers" >
								<ListItemIcon><PeopleIcon /></ListItemIcon>
								<ListItemText primary="Customers " />
							</ListItem>
						</Link>
						<Link to="/admin/verification_pending" className="Link" style={{color:'black'}}>
							<ListItem button key="Verification Pending" >
								<ListItemIcon><WatchLaterIcon /></ListItemIcon>
								<ListItemText primary="Verification Pending" />
							</ListItem>
						</Link>
						<Link to="/admin/deleted_customer" className="Link" style={{color:'black'}}>
							<ListItem button key="Delete Customer" >
								<ListItemIcon><DeleteIcon /></ListItemIcon>
								<ListItemText primary="Deleted Customers" />
							</ListItem>
						</Link>
						<Link to="/admin/inactive_subscription" className="Link" style={{color:'black'}}>
							<ListItem button key="Inactive Subscriptions" >
								<ListItemIcon><LockIcon /></ListItemIcon>
								<ListItemText primary="Inactive Subscriptions" />
							</ListItem>
						</Link>
						<Divider/>
						<Link onClick={logout} to="/" className="Link" style={{color:'black'}}>
							<ListItem button key="logout_link">
								<ListItemIcon><ExitToAppIcon /></ListItemIcon>
								<ListItemText primary="Logout" />
							</ListItem>
						</Link>
					</List>
				</Drawer>
				<SnackbarProvider
					ref={notistackRef}
					action={(key) => (
						<IconButton  onClick={onClickDismiss(key)} >
						<CloseIcon style={{color:'white',width:20,height:20}}></CloseIcon></IconButton>
					)}
					maxSnack={3} 
					anchorOrigin={{vertical: 'top', horizontal: 'right'}}> 
					<main
						className={clsx(classes.content, {
							[classes.contentShift]: open,
						})}>  
						<div className={classes.toolbar} />
						<Switch>
							<Route exact path="/admin">
								<Dashboard />
							</Route>
							<Route exact path="/admin/dashboard">
								<Dashboard />
							</Route>
							<Route exact path="/admin/verification_pending">
								<NewUsers />
							</Route>
							<Route exact path="/admin/deleted_customer">
								<DeleteCustomer/>
							</Route>							
								
							<Route exact path="/admin/customers">
								<Customers />
							</Route>
							<Route exact path="/admin/profile/:id" component={Profile} />
							<Route exact path="/admin/deletedCustomerView/:id" component={DeletedCustomerView}/>
							<Route exact path="/admin/subscription_details/:id" component={SubscriptionDetails} />
							<Route exact path="/admin/subscription/add/:id" component={SubscriptionAdd} />
							<Route exact path="/admin/subscription/edit/:id" component={SubscriptionEdit} />
							<Route exact path="/admin/inactive_subscription">
								<InactiveSubscription/>
							</Route>
							<Route exact path="/admin/inactive">
								<InActive />
							</Route>
							<Route exact path="/admin/partners">
								<Partners />
							</Route>
							<Route exact path="/admin/renewals">
								<Renewals />
							</Route>
							<Route exact path="/admin/subscriptions">
								<Subscriptions />
							</Route>
						</Switch>
					</main>
				</SnackbarProvider>
			</div>
		</Router>
  	);
}
/*<Link to="/admin/inactive" className="Link" style={{color:'black'}}>
	<ListItem button key="inactive_Tenants" >
		<ListItemIcon><CancelIcon /></ListItemIcon>
		<ListItemText primary="Inactive Tenants" />
	</ListItem>
</Link>
<Link to="/admin/renewals" className="Link" style={{color:'black'}}>
	<ListItem button key="Renewals" >
		<ListItemIcon><CachedIcon /></ListItemIcon>
		<ListItemText primary="Renewals" />
	</ListItem>
</Link>
<Link to="/admin/partners" className="Link" style={{color:'black'}}>
	<ListItem button key="Partners" >
		<ListItemIcon><GroupAddSharpIcon /></ListItemIcon>
		<ListItemText primary="Partners" />
	</ListItem>
</Link>*/